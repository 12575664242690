import styled from '@emotion/styled'
import { Avatar, FlexColumn, FlexRow, Text, colorTokens, mobileMediaQuery, spacing } from '@orus.eu/pharaoh'
import type { ComponentProps, FunctionComponent, ReactNode } from 'react'
import { memo } from 'react'

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${spacing[50]};
  align-items: center;
  background-color: ${colorTokens['color-bg-base-normal']};
  padding: ${spacing[50]} ${spacing[50]};
  border: 1px solid ${colorTokens['color-stroke-base']};
  border-radius: 8px 8px 0 0;

  span {
    display: flex;
    gap: ${spacing[50]};
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  ${mobileMediaQuery} {
    border-radius: 0;
  }
`

export const DataList = styled(FlexColumn)`
  justify-content: flex-start;
  border-left: 1px solid ${colorTokens['color-stroke-base']};
  border-right: 1px solid ${colorTokens['color-stroke-base']};
  border-bottom: 1px solid ${colorTokens['color-stroke-base']};
  width: 100%;
  gap: 0;
  padding: 0;
  border-radius: 0 0 8px 8px;
`

export const DataRow = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colorTokens['color-stroke-base']};
  width: 100%;
  gap: ${spacing[50]};
  padding: 0 ${spacing[50]};

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 8px 8px;
  }

  & > *:first-child {
    width: 240px;
    min-width: 240px;
    padding: ${spacing[50]} 0;
  }

  & > *:nth-child {
    width: 100%;
    padding: 0;
    min-width: 200px;
  }

  & > *:nth-child(2) {
    margin-left: auto;
    width: 100%;
    padding: 0;
    min-width: 200px;
  }
  ${mobileMediaQuery} {
    & > *:first-child {
      width: fit-content;
      min-width: 240px;
      padding: ${spacing[50]} 0;
    }

    & > *:nth-child {
      width: fit-content;
      min-width: 240px;
    }

    & > *:nth-child(2) {
      width: fit-content;
      min-width: fit-content;
    }
    flex-wrap: wrap;
    padding-top: ${spacing[30]};
    padding-bottom: ${spacing[30]};
    gap: ${spacing[30]};
  }
`
export const BackofficeDataCard: FunctionComponent<{
  title: string
  icon: ComponentProps<typeof Avatar>['icon']
  headerLink?: ReactNode
  children: ReactNode
}> = memo(function BackofficeDataCard({ headerLink, title, icon, children }) {
  return (
    <div>
      <SectionHeader>
        <span>
          <Avatar size="30" icon={icon} />
          <Text variant="captionMedium">{title}</Text>
        </span>
        <Text>{headerLink}</Text>
      </SectionHeader>

      {children}
    </div>
  )
})
